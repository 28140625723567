import React from "react";
import "./Lead.css";

function Lead(props) {
    return (
        <div className="lead">
            <div className="lead-content">
                <h1>Samuel Cunha</h1>
                <h2>&nbsp;</h2>
            </div>

            <div className="lead-overlay"></div>
        </div>
    );
}

export default Lead;
