import React from "react";
import "./App.css";
import "./components/container/Container.js";
import Container from "./components/container/Container.js";
import Lead from "./components/lead/Lead.js";
import Social from "./components/social/Social.js";

function App() {
    return (
        <div className="App">
            <Container>
                <Lead></Lead>
                <Social></Social>
            </Container>
        </div>
    );
}

export default App;
