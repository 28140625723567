import React from "react";
import "./Social.css";
import { IconContext } from "react-icons";
import { FaGithub } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaEnvelopeSquare } from "react-icons/fa";

function Social(props) {
    const onClickGitHub = React.useCallback(event => {
        window.open("https://github.com/samuelcunha", "_blank");
        if (window.firebase)
            window.firebase.analytics().logEvent("CLICK_GITHUB");
    });

    const onClickLinkedin = React.useCallback(event => {
        window.open(
            "https://br.linkedin.com/in/samuel-cunha-b6aa446b",
            "_blank"
        );
        if (window.firebase)
            window.firebase.analytics().logEvent("CLICK_LINKEDIN");
    });

    const onClickEmail = React.useCallback(event => {
        window.open("mailto:samureira@gmail.com", "_blank");
        if (window.firebase)
            window.firebase.analytics().logEvent("CLICK_EMAIL");
    });
    return (
        <div className="social">
            <div className="social-box">
                <div className="social-item">
                    <div className="social-button" onClick={onClickGitHub}>
                        <IconContext.Provider
                            value={{
                                color: "#FFFFFF"
                            }}
                        >
                            <div>
                                <FaGithub></FaGithub>
                            </div>
                        </IconContext.Provider>
                    </div>
                </div>
                <div className="social-item">
                    <div className="social-button" onClick={onClickLinkedin}>
                        <IconContext.Provider
                            value={{
                                color: "#FFFFFF"
                            }}
                        >
                            <div>
                                <FaLinkedinIn></FaLinkedinIn>
                            </div>
                        </IconContext.Provider>
                    </div>
                </div>
                <div className="social-item">
                    <div className="social-button" onClick={onClickEmail}>
                        <IconContext.Provider
                            value={{
                                color: "#FFFFFF"
                            }}
                        >
                            <div>
                                <FaEnvelopeSquare></FaEnvelopeSquare>
                            </div>
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Social;
